import {
  FETCH_PUBLIC_HOLIDAYS_ERROR,
  FETCH_PUBLIC_HOLIDAYS_SUCCESS,
  FETCH_PUBLIC_HOLIDAYS_START,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  list: [],
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_PUBLIC_HOLIDAYS_START:
      return { ...state, isFetching: true };
    case FETCH_PUBLIC_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        list: action.payload.map(holiday => {
          return {
            date: holiday.date,
            startDate: holiday.date,
            endDate: holiday.date,
            name: holiday.localName,
            vacationType: 'publicHoliday',
            vacationTypeColor: '#ff1744',
          };
        }),
        isFetching: false,
      };
    }
    case FETCH_PUBLIC_HOLIDAYS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
